import '../../../../../../src/components/controls/inputs/neb-checkbox';
import '../../../../../neb-lit-components/src/components/tables/neb-table-ledger-payments';
import '../../../../../../src/components/filters/neb-filters-ledger-payments';

import { openPopup } from '@neb/popup';
import { navigate } from '@neb/router';
import { html, css, unsafeCSS } from 'lit';
import moment from 'moment-timezone';

import { autoAllocatePayments } from '../../../../../../src/api-clients/auto-allocation';
import { getLocations } from '../../../../../../src/api-clients/locations';
import { openPayfacReaderPopup } from '../../../../../../src/features/payfac/utils';
import { openOverlayAllocatePayment } from '../../../../../../src/utils/allocate-payment/allocate-payment-util';
import { getAutoAllocationBanner } from '../../../../../../src/utils/auto-allocation';
import { openPayment } from '../../../../../../src/utils/payment-util';
import {
  ERROR_AUTO_ALLOCATE_PAYMENT,
  NO_ITEMS_INITIAL_LOAD,
  plural,
} from '../../../../../../src/utils/user-message';
import * as patientApiClient from '../../../../../neb-api-client/src/patient-api-client';
import { getPatientPaymentPayers } from '../../../../../neb-api-client/src/payers';
import {
  getElectronicPayment,
  refundElectronicPayment,
} from '../../../../../neb-api-client/src/payments/electronic-payments-api-client';
import {
  getPayments,
  refundPayment,
  voidPayment,
  printReceipts,
  bulkUpdatePaymentAssociations,
  printItemizedReceipt,
  getPaymentDetail,
} from '../../../../../neb-api-client/src/payments-api-client';
import { getActiveProviderUsers } from '../../../../../neb-api-client/src/practice-users-api-client';
import {
  openSuccess,
  openError,
} from '../../../../../neb-dialog/neb-banner-state';
import CollectionPage, {
  ELEMENTS as BASE_ELEMENTS,
} from '../../../../../neb-lit-components/src/components/neb-page-collection';
import {
  OVERLAY_KEYS,
  openOverlay,
} from '../../../../../neb-lit-components/src/utils/overlay-constants';
import { PAYMENT_ACTION_KEYS } from '../../../../../neb-popup/src/neb-popup-payment-action';
import { POPUP_RENDER_KEYS } from '../../../../../neb-popup/src/renderer-keys';
import { store } from '../../../../../neb-redux/neb-redux-store';
import {
  CSS_SPACING,
  CSS_FONT_WEIGHT_BOLD,
  CSS_COLOR_GREY_3,
} from '../../../../../neb-styles/neb-variables';
import { BILLING_NOTE_TYPES } from '../../../../../neb-utils/constants';
import { parseDate } from '../../../../../neb-utils/date-util';
import {
  ELECTRONIC_PAYMENT_TYPES,
  REFUND_MESSAGES,
  GENIUS_SALE_TYPES,
} from '../../../../../neb-utils/electronic-payments-util';
import {
  FEATURE_FLAGS,
  getFeatures,
} from '../../../../../neb-utils/feature-util';
import {
  DEFAULT_NAME_OPTS,
  centsToCurrency,
  formatPhoneNumber,
  objToName,
} from '../../../../../neb-utils/formatters';
import {
  is500Error,
  isElectronicPayment,
  isRefunded,
} from '../../../../../neb-utils/neb-payment-util';
import { printPdf } from '../../../../../neb-utils/neb-pdf-print-util';
import {
  NEBULA_REFRESH_EVENT,
  REFRESH_CHANGE_TYPE,
} from '../../../../../neb-utils/neb-refresh';
import {
  EMPTY_RESPONSE,
  FetchService,
} from '../../../../../neb-utils/services/fetch';

import { VOIDED_BANNER_MESSAGE } from './neb-patient-payment-controller';

export const MENU_HEIGHT = 416;

export const REFUNDED_BANNER_MESSAGE = {
  success: 'Payment refunded successfully',
  error: 'An error occurred when refunding the payment',
};

export const UPDATE_PAYMENT_PROVIDER_LOCATION_BANNER_MESSAGE = {
  success: 'Payments associated successfully',
  error: 'An error occurred when associating provider/location to payments',
};

export const ELEMENTS = {
  ...BASE_ELEMENTS,
  content: { id: 'content' },
  checkBox: {
    id: 'checkbox',
  },
  filters: {
    id: 'filters',
  },
  footer: { id: 'footer' },
  footerCells: { selector: '.footer-cell' },
};

class NebPaymentsListPage extends CollectionPage {
  static get properties() {
    return {
      __marginBottom: Number,
      __patientPaymentPayers: Array,
      __hasOwlItemizedReceiptBulkGeneration: {
        type: Boolean,
        reflect: true,
      },
      __selectionState: Object,
      __allPayments: Object,

      mode: String,
      patientId: String,
      getDynamicLeadingHeaderActions: {
        type: Function,
      },
    };
  }

  static get config() {
    return {
      unifyForm: true,
      useFetch: true,
      showInactiveFilter: null,
      initialSortKey: 'transactionDate',
      initialSortOrder: 'desc',
      description: 'Review payments and perform updates.',
      tableConfig: [],
    };
  }

  initState() {
    super.initState();

    this.__tableState = {
      body: {},
      hideInactive: true,
      filteredCount: 0,
      pageIndex: 0,
      pageCount: 1,
      pageSize: 10,
      searchText: '',
      pageItems: [],
      itemsTotal: {},
      sortParams: {
        key: this.getConfig().initialSortKey,
        dir: this.getConfig().initialSortOrder,
      },
    };

    this.__allPayments = { data: [] };

    this.__selectionState = {
      selectedIds: new Set(),
      selectAllActive: false,
    };

    this.patientId = null;
    this.__patientPaymentPayers = [];
    this.__marginBottom = 0;
    this.__hasDisableInitialLoadFF = false;
    this.__hasLsTaxDiscountFF = false;
    this.__hasAppliedFilters = false;
    this.__hasOwlItemizedReceiptBulkGeneration = false;
    this.__isRefreshing = false;

    this.getDynamicLeadingHeaderActions = () => this.__getHeaderActions();

    this.onPaymentAction = () => {};

    this.onChange = () => {};
  }

  initHandlers() {
    super.initHandlers();
    this.handlers = {
      ...this.handlers,
      filter: async (model, isButtonPress) => {
        if (isButtonPress) {
          this.__hasAppliedFilters = true;
        }

        const patientId = model.patientId || undefined;
        const paymentId = model.paymentId || undefined;
        const payerIds = model.payerIds?.length ? model.payerIds : undefined;
        const dosFrom = model.dateOfService?.from
          ? model.dateOfService.from.format('YYYY-MM-DD')
          : undefined;
        const dosTo = model.dateOfService?.to
          ? model.dateOfService.to.format('YYYY-MM-DD')
          : undefined;
        const statuses = model.statuses?.length ? model.statuses : undefined;

        const paymentMethods = model.paymentMethods?.length
          ? model.paymentMethods
          : undefined;

        const paymentTypes = model.paymentTypes?.length
          ? model.paymentTypes
          : undefined;

        const paymentAmountFrom =
          model.paymentAmount?.min || model.paymentAmount?.min === 0
            ? model.paymentAmount.min
            : undefined;

        const paymentAmountTo =
          model.paymentAmount?.max || model.paymentAmount?.max === 0
            ? model.paymentAmount.max
            : undefined;

        const availableAmountFrom =
          model.availableAmount?.min || model.availableAmount?.min === 0
            ? model.availableAmount.min
            : undefined;

        const availableAmountTo =
          model.availableAmount?.max || model.availableAmount?.max === 0
            ? model.availableAmount.max
            : undefined;

        const providers = model.providers?.length ? model.providers : undefined;

        const locations = model.locations?.length ? model.locations : undefined;

        this.service.setQuery('providers', providers);
        this.service.setQuery('locations', locations);
        this.service.setQuery('patientId', patientId);
        this.service.setQuery('paymentId', paymentId);
        this.service.setQuery('payerIds', payerIds);
        this.service.setQuery('dosFrom', dosFrom);
        this.service.setQuery('dosTo', dosTo);
        this.service.setQuery('statuses', statuses);
        this.service.setQuery('paymentMethods', paymentMethods);
        this.service.setQuery('paymentTypes', paymentTypes);
        this.service.setQuery('paymentAmountFrom', paymentAmountFrom);
        this.service.setQuery('paymentAmountTo', paymentAmountTo);
        this.service.setQuery('availableAmountFrom', availableAmountFrom);
        this.service.setQuery('availableAmountTo', availableAmountTo);

        this.service.setPageIndex(0);

        if (this.patientId && this.__hasOwlItemizedReceiptBulkGeneration) {
          this.__clearAllSelections();
          await this.__fetchAllPayments();
        }
      },
      checkBoxToggle: e => {
        e.stopPropagation();
      },
      openBillingNotesOverlay: async ({
        amount,
        parentId,
        paymentNumber,
        payerName,
        transactionDate,
      }) => {
        const result = await openOverlay(OVERLAY_KEYS.BILLING_NOTE, {
          parentType: BILLING_NOTE_TYPES.PAYMENT,
          parentId,
          parentData: {
            transactionDate: parseDate(transactionDate)
              .startOf('day')
              .format('MM/DD/YYYY'),
            paymentId: paymentNumber,
            amount,
            payer: payerName,
          },
          patientId: this.patientId,
        });

        if (result) this.fetch();
      },
      sort: (_, params) => this.service.setSortParams(params[0]),
      fetchData: query => {
        if (this.__isInitialLoadDisabled()) {
          return EMPTY_RESPONSE;
        }

        const updatedQuery = {
          ...query,
          excludeUnboundPayments: true,
        };

        return getPayments(this.patientId, updatedQuery);
      },
      selectItemCell: (_, key, index) => this.__selectCell(key, index),
      tableChange: e => this.__handleRowCheck(e),
      refundPayment: () => this.__refundPayment(),
      voidPayment: () => this.__voidPayment(),
      allocatePayment: () => this.__allocatePayment(),
      splitPayment: () => this.__splitPayment(),
      printPayment: async () => {
        const patientPayments =
          await this.__getCheckedRowsWithOpenEdgeReceipts();
        printPdf(printReceipts({ patientPayments }));
      },
      printItemizedReceipts: () => {
        this.__printItemizedReceipts();
      },
      editPayer: ({ payerId }) => this.__openPayerOverlay(payerId),
      autoAllocateAll: async () => {
        const paymentIds = this.__getValidCheckedPaymentIds();

        try {
          const { data: allocations } = await autoAllocatePayments({
            paymentIds,
          });

          this.fetch();
          this.onPaymentAction();
          store.dispatch(getAutoAllocationBanner(allocations));
        } catch (e) {
          console.error(e);
          store.dispatch(openError(ERROR_AUTO_ALLOCATE_PAYMENT));
        }
      },
      onSelectAllOnCurrentPage: () => {
        this.__setAllCheckboxes(true);
      },
      onSelectAllItems: () => this.__selectAllPayments(this.__allPayments),
      onDeselectAll: () => {
        this.__clearAllSelections();
        this.__setAllCheckboxes(false);
      },
      toggleCheckbox: checked => {
        if (this.__hasOwlItemizedReceiptBulkGeneration) {
          this.__setAllCheckboxes(!checked);
        } else {
          if (checked || this.__selectionState.selectAllActive) {
            this.__clearAllSelections();
          }
          this.__setAllCheckboxes(!checked);
        }
      },
      onUpdateProviderLocation: () => this.__associatePaymentProviderLocation(),
      navigateToPatientLedger: ({ patientId, path = 'activity' }) => {
        navigate(`/patients/${patientId}/ledger/${path}`);
      },
      selectPage: pageIndex => {
        if (this.__hasOwlItemizedReceiptBulkGeneration) {
          const checkedRows = this.__getCheckedRows();
          checkedRows.forEach(item => {
            this.__selectionState.selectedIds.add(item.id);
          });
        }

        this.service.setPageIndex(pageIndex);
      },
    };
  }

  initService() {
    this.service = new FetchService(
      {
        onChange: state => {
          const updatedPageItems = state.pageItems.map(item => ({
            ...item,
            checked: this.__hasOwlItemizedReceiptBulkGeneration
              ? this.__selectionState.selectedIds.has(item.id)
              : false,
          }));

          this.__tableState = {
            ...state,
            pageItems: updatedPageItems,
            selectAllChecked: this.__hasOwlItemizedReceiptBulkGeneration
              ? this.__areAllCurrentPageItemsSelected()
              : false,
          };

          this.changeOccurred(this.__tableState);
        },
      },
      this.handlers.fetchData,
      {
        ...this.__tableState,
        initialQuery: {
          dosFrom: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          dosTo: moment().format('YYYY-MM-DD'),
        },
      },
    );
  }

  async connectedCallback() {
    const features = await getFeatures();

    this.__hasDisableInitialLoadFF = features.includes(
      FEATURE_FLAGS.DISABLE_INITIAL_LOAD,
    );

    this.__hasLsTaxDiscountFF = features.includes(
      FEATURE_FLAGS.LS_TAX_DISCOUNT,
    );

    this.__hasOwlItemizedReceiptBulkGeneration = features.includes(
      FEATURE_FLAGS.OWL_ITEMIZED_RECEIPT_BULK_GENERATION,
    );

    super.connectedCallback();

    if (this.patientId) {
      this.__getPatientPaymentPayers();

      this.__headerSideButtonConfig = {
        label: 'Add New Discount',
        leadingIcon: 'plus',
        onClick: () => this.__openDiscountsOverlay(this.__patientId),
      };
    }

    this.fetch();

    window.addEventListener(NEBULA_REFRESH_EVENT, event =>
      this.__handleRefresh(event),
    );
  }

  __handleRefresh(event) {
    const { changed } = event.detail;

    if (changed.includes(REFRESH_CHANGE_TYPE.LEDGER)) {
      if (this.__isRefreshing) {
        return;
      }

      this.__isRefreshing = true;

      const currentPaymentIds = new Set(
        this.__allPayments?.data?.map(item => item.id) || [],
      );

      const previousSelectedIds = new Set(this.__selectionState.selectedIds);

      this.fetch();

      if (this.patientId && this.__hasOwlItemizedReceiptBulkGeneration) {
        Promise.resolve(this.__fetchAllPayments())
          .then(result => {
            const newItems = result.data.filter(
              item => !currentPaymentIds.has(item.id),
            );

            if (newItems.length > 0 && this.__selectionState.selectAllActive) {
              this.__selectionState.selectAllActive = false;

              this.__selectionState.selectedIds = previousSelectedIds;
            }

            if (this.__tableState.pageItems) {
              const updatedPageItems = this.__tableState.pageItems.map(
                item => ({
                  ...item,
                  checked: this.__selectionState.selectedIds.has(item.id),
                }),
              );

              this.__tableState = {
                ...this.__tableState,
                pageItems: updatedPageItems,
              };
            }

            this.requestUpdate();
            this.__isRefreshing = false;
          })
          .catch(error => {
            console.error('Error fetching payments:', error);
            this.__isRefreshing = false;
          });
      } else {
        this.__isRefreshing = false;
      }
    }
  }

  __buildConfig() {
    return [
      {
        flex: css`0 0 30px`,
        key: 'checked',
        label: '',
      },
      ...(!this.patientId
        ? [
            {
              truncate: true,
              key: 'patientName',
              label: 'Patient',
              flex: css`1 0 0`,
            },
          ]
        : []),
      {
        sortable: true,
        truncate: true,
        key: 'transactionDate',
        label: 'Date',
        flex: css`1 0 0`,
        formatter: d => d.format('MM/DD/YYYY'),
      },
      {
        truncate: true,
        key: 'paymentNumber',
        label: 'Payment ID',
        flex: css`1 0 0`,
        link: true,
      },
      {
        sortable: true,
        truncate: true,
        key: 'status',
        label: 'Status',
        flex: css`1 0 0`,
      },
      {
        sortable: false,
        truncate: true,
        key: 'payerName',
        label: 'Payer',
        flex: css`1 0 0`,
      },
      {
        truncate: true,
        key: 'codePayment',
        label: 'Type',
        flex: css`1 0 0`,
        formatter: c => c.code,
      },
      {
        truncate: true,
        key: 'paymentMethod',
        label: 'Method',
        flex: css`1 0 0`,
      },
      {
        sortable: true,
        truncate: true,
        key: 'amount',
        label: 'Payment Amount',
        flex: css`1 0 0`,
        formatter: (a, payment) => {
          let amountToFormat = a;

          if (this.patientId && !payment.patientId) {
            if (payment.credits && payment.credits.length > 0) {
              amountToFormat = payment.credits[0].amount;
            }
          }

          return centsToCurrency(amountToFormat);
        },
      },
      {
        sortable: true,
        truncate: true,
        key: 'available',
        label: 'Available',
        flex: css`1 0 0`,
        formatter: (a, payment) =>
          !this.patientId ||
          (payment.patientId &&
            this.patientId &&
            this.patientId === payment.patientId)
            ? centsToCurrency(a)
            : '-',
      },
    ];
  }

  __totalsConfig() {
    return [
      {
        flex: css`0 0 30px`,
        key: 'checked',
        label: '',
      },
      ...(!this.patientId
        ? [
            {
              key: 'patientName',
              label: 'Patient',
              flex: css`1 0 0`,
            },
          ]
        : []),
      {
        key: 'transactionDate',
        label: 'Date',
        flex: css`1 0 0`,
      },
      {
        key: 'paymentNumber',
        label: 'Payment ID',
        flex: css`1 0 0`,
      },
      {
        key: 'status',
        label: 'Status',
        flex: css`1 0 0`,
      },
      {
        key: 'payerName',
        label: 'Payer',
        flex: css`1 0 0`,
      },
      {
        key: 'codePayment',
        label: 'Type',
        flex: css`1 0 0`,
      },
      {
        key: 'paymentMethod',
        label: 'Method',
        flex: css`1 0 0`,
      },
      {
        key: 'amount',
        label: 'Payment Amount',
        flex: css`1 0 0`,
        formatter: a => centsToCurrency(a),
      },
      {
        key: 'available',
        label: 'Available',
        flex: css`1 0 0`,
        formatter: a => centsToCurrency(a),
      },
    ];
  }

  fetch() {
    this.service.fetch();
  }

  __isInitialLoadDisabled() {
    return (
      this.__hasDisableInitialLoadFF &&
      !this.__hasAppliedFilters &&
      !this.patientId
    );
  }

  __calculateMarginBottom() {
    const { length } = this.__tableState.pageItems;

    if (length <= 2) {
      this.__marginBottom = MENU_HEIGHT;
    } else {
      this.__marginBottom = 0;
    }
  }

  async __getCheckedRowsWithOpenEdgeReceipts() {
    const rows = this.__getCheckedRows();
    const rowsWithReceipts = rows.map(async row => {
      if (isElectronicPayment(row)) {
        const ePayment = await getElectronicPayment(row.electronicPaymentId);

        if (ePayment && ePayment.receipt) {
          row.receipt = {
            text: JSON.parse(ePayment.receipt).text.customer_receipt,
          };
        }
      }

      return row;
    });

    const resolvedRows = await Promise.all(rowsWithReceipts);

    return resolvedRows;
  }

  __isItemSelected(itemId) {
    return (
      this.__selectionState.selectAllActive ||
      this.__selectionState.selectedIds.has(itemId)
    );
  }

  __updateSelection(itemId, isSelected) {
    if (isSelected) {
      this.__selectionState.selectedIds.add(itemId);
    } else {
      this.__selectionState.selectedIds.delete(itemId);
    }

    this.requestUpdate();
  }

  __setAllCheckboxes(checked) {
    const pageItems = this.__tableState.pageItems.map(item => {
      if (checked) {
        this.__selectionState.selectedIds.add(item.id);
      } else {
        this.__selectionState.selectedIds.delete(item.id);
      }

      return { ...item, checked };
    });

    this.__tableState = {
      ...this.__tableState,
      pageItems: [...pageItems],
      selectAllChecked: checked,
    };

    this.requestUpdate();
  }

  __getCheckedRows() {
    if (!this.__hasOwlItemizedReceiptBulkGeneration) {
      return this.__tableState.pageItems.filter(item => item.checked);
    }

    return this.__allPayments.data.filter(payment =>
      this.__selectionState.selectedIds.has(payment.id),
    );
  }

  async __fetchAllPayments() {
    this.__allPayments = await getPayments(this.patientId, {
      sortKey: this.__tableState.sortParams.key,
      sortDir: this.__tableState.sortParams.dir,
      pageIndex: 0,
      ...this.service.__customQuery,
      excludeUnboundPayments: true,
    });
  }

  __selectAllPayments(payments) {
    if (!payments || !payments.data) {
      return;
    }

    this.__selectionState.selectAllActive = true;

    if (this.__hasOwlItemizedReceiptBulkGeneration) {
      const currentPaymentIds = new Set(
        this.__allPayments?.data?.map(item => item.id) || [],
      );

      this.__selectionState.selectedIds = new Set(
        payments.data
          .filter(item => currentPaymentIds.has(item.id))
          .map(item => item.id),
      );
    } else {
      this.__selectionState.selectedIds = new Set(
        payments.data.map(item => item.id),
      );
    }

    this.__setAllCheckboxes(true);
    this.requestUpdate();
  }

  __clearAllSelections() {
    this.__selectionState.selectAllActive = false;
    this.__selectionState.selectedIds.clear();

    this.__setAllCheckboxes(false);
    this.requestUpdate();
  }

  async __openPayerOverlay(payerId) {
    await openOverlay(OVERLAY_KEYS.PAYER_PLAN, { id: payerId });
  }

  async __openDiscountsOverlay(patientId) {
    const res = await openOverlay(OVERLAY_KEYS.DISCOUNT, {
      patientId,
      transactionDateFrom: parseDate().add(-1, 'month'),
      transactionDateTo: parseDate(),
    });

    if (res) {
      this.fetch();
      this.onPaymentAction();
    }
  }

  async __voidPayment() {
    const [payment] = this.__getCheckedRows();

    const paymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
      action: PAYMENT_ACTION_KEYS.VOID,
      paymentId: payment.paymentNumber,
      payer: payment.payerName,
      amount: centsToCurrency(payment.amount),
    });

    if (paymentAction) {
      const voidPaymentBody = {
        paymentId: payment.id,
        codeRefundId: paymentAction.selectedReason.id,
      };

      try {
        await voidPayment(voidPaymentBody);
        this.fetch();
        this.onPaymentAction();
        store.dispatch(openSuccess(VOIDED_BANNER_MESSAGE.success));
      } catch (e) {
        store.dispatch(openError(VOIDED_BANNER_MESSAGE.error));
      }
    }
  }

  async __refundElectronicPayment(payment, opts = {}) {
    const ePayment = await getElectronicPayment(payment.electronicPaymentId);

    let refundPaymentAction;

    if (payment.payerPlan) {
      refundPaymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
        action: PAYMENT_ACTION_KEYS.REFUND,
        paymentId: payment.paymentNumber,
        payer: payment.payerName,
        amount: centsToCurrency(payment.amount),
        paymentMethod: `${payment.paymentMethod} - ${
          payment.maskedCardDescription
        }`,
        selectedReader: opts.selectedReader,
        selectedReason: opts.selectedReason,
      });

      if (refundPaymentAction && refundPaymentAction.selectedReason) {
        const refundPaymentBody = {
          paymentId: payment.id,
          codeRefundId: refundPaymentAction.selectedReason.id,
          refundMethod: refundPaymentAction.refundMethod,
          amount: refundPaymentAction.amount,
        };

        try {
          const result = await refundPayment(refundPaymentBody);
          this.__paymentDetail = result;

          this.fetch();
          this.onPaymentAction();
          store.dispatch(openSuccess(REFUNDED_BANNER_MESSAGE.success));

          return undefined;
        } catch (e) {
          console.error(e);
          store.dispatch(openError(REFUNDED_BANNER_MESSAGE.error));

          return undefined;
        }
      }
    } else {
      refundPaymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
        action: PAYMENT_ACTION_KEYS.REFUND_ELECTRONIC,
        paymentId: payment.paymentNumber,
        payer: payment.payerName,
        paymentMethod: `${payment.paymentMethod} - ${
          payment.maskedCardDescription
        }`,
        amount: centsToCurrency(payment.amount),
        selectedReader: opts.selectedReader,
        selectedReason: opts.selectedReason,
        ePayment,
      });
    }

    if (refundPaymentAction) {
      if (refundPaymentAction.action === PAYMENT_ACTION_KEYS.REFUND) {
        if (refundPaymentAction && refundPaymentAction.selectedReason) {
          const refundPaymentBody = {
            paymentId: payment.id,
            codeRefundId: refundPaymentAction.selectedReason.id,
            refundMethod: refundPaymentAction.refundMethod,
            amount: refundPaymentAction.amount,
          };

          try {
            await refundPayment(refundPaymentBody);
            this.fetch();
            this.onPaymentAction();
            store.dispatch(openSuccess(REFUNDED_BANNER_MESSAGE.success));
          } catch (e) {
            console.error(e);
            store.dispatch(openError(REFUNDED_BANNER_MESSAGE.error));
          }
        }

        return undefined;
      }

      let done = false;
      let paymentSuccess;
      let geniusRefund;

      while (!done) {
        done = true;

        if (ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT) {
          const payerPlanId = this.__paymentDetail
            ? this.__paymentDetail.payerPlanId || null
            : null;

          geniusRefund = await openPayfacReaderPopup({
            amount: refundPaymentAction.amount / 100,
            merchantAccounts: [
              {
                id: ePayment.merchantAccountId,
                cardReaders: [refundPaymentAction.selectedReader],
                active: true,
              },
            ],
            holderId: this.patientId,
            title: 'Merchant Account Details',
            subheader:
              'Select the merchant account details for this transaction, then follow the prompts on the card reader.',
            message: 'Payment Amount',
            confirmLabel: 'Submit',
            holderType: 'patient',
            geniusSaleType: GENIUS_SALE_TYPES.RETURN,
            logContent: {
              ...ePayment,
              patientId: this.patientId || null,
              payerPlanId,
              action: 'refund - cardReader - neb-payments-list-page',
            },
            pendingModel: {
              action: 'refund',
              patientId: this.patientId || null,
              payerPlanId,
              amount: refundPaymentAction.amount,
              referenceId: ePayment.referenceId,
              electronicPaymentId: ePayment.id,
              electronicReferenceId: ePayment.referenceId,
              codeRefundId: refundPaymentAction.selectedReason,
            },
          });

          if (!geniusRefund) {
            this.__refundElectronicPayment(payment, refundPaymentAction);

            return {};
          }

          if (geniusRefund.status === 'DECLINED') {
            const response = await openPopup(
              POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
              {
                ...(ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                  ? REFUND_MESSAGES.FAILED_400_MANUAL
                  : REFUND_MESSAGES.FAILED_400),
                subheader: geniusRefund.status,
                hyperLink:
                  ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                    ? 'Process Refund Manually'
                    : '',
              },
            );

            if (response.action === 'link') {
              this.__refundPayment(true);
            }
            return {};
          }
        }

        try {
          const result = await openPopup(
            POPUP_RENDER_KEYS.TRANSACTION_PROCESSING,
            {
              promise: refundElectronicPayment(
                ePayment.merchantAccountId,
                ePayment.id,
                {
                  ...geniusRefund,
                  amount: refundPaymentAction.amount,
                  logContent: {
                    action: 'cardOnFile - neb-payments-list-page',
                    rawModel: ePayment,
                  },
                },
              ),
            },
          );

          if (result.success) {
            paymentSuccess = true;

            const refundPaymentBody = {
              paymentId: payment.id,
              codeRefundId: refundPaymentAction.selectedReason.id,
              refundMethod: refundPaymentAction.refundMethod,
              cardRefundId: result.refund.returnId || result.refund.saleId,
              amount: refundPaymentAction.amount,
            };

            await refundPayment(refundPaymentBody);
            this.fetch();
            this.onPaymentAction();
          }

          if (result) {
            let response;

            if (result.success) {
              if (isRefunded(result.status)) {
                response = await openPopup(
                  POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                  REFUND_MESSAGES.APPROVED,
                );
              } else {
                response = await openPopup(
                  POPUP_RENDER_KEYS.MERCHANT_RESPONSE,
                  REFUND_MESSAGES.VOIDED,
                );
              }
            } else if (is500Error(result.code)) {
              response = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
                ...REFUND_MESSAGES.FAILED_500,
                subheader: result.status,
              });

              if (response.action === 'confirm') {
                done = false;
              }
            } else {
              response = await openPopup(POPUP_RENDER_KEYS.MERCHANT_RESPONSE, {
                ...(ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                  ? REFUND_MESSAGES.FAILED_400_MANUAL
                  : REFUND_MESSAGES.FAILED_400),
                subheader: result.status,
                hyperLink:
                  ePayment.type === ELECTRONIC_PAYMENT_TYPES.DEBIT
                    ? 'Process Refund Manually'
                    : '',
              });

              if (response.action === 'link') {
                this.__refundPayment(true);
              }
            }
          }
        } catch (e) {
          store.dispatch(openError(REFUNDED_BANNER_MESSAGE.error));
        }
      }

      if (paymentSuccess) {
        store.dispatch(openSuccess(REFUNDED_BANNER_MESSAGE.success));
      }
    }

    return refundPaymentAction;
  }

  async __refundPayment(skipElectronic = false) {
    let refundPaymentAction;

    const [payment] = this.__getCheckedRows();

    if (payment.cardSaleId && !skipElectronic) {
      refundPaymentAction = await this.__refundElectronicPayment(payment);

      if (
        refundPaymentAction &&
        refundPaymentAction.action !== PAYMENT_ACTION_KEYS.REFUND
      ) {
        return;
      }
    } else {
      refundPaymentAction = await openPopup(POPUP_RENDER_KEYS.PAYMENT_ACTION, {
        action: PAYMENT_ACTION_KEYS.REFUND,
        paymentId: payment.paymentNumber,
        payer: payment.payerName,
        paymentMethod: payment.electronicPaymentId
          ? `${payment.paymentMethod} - ${payment.maskedCardDescription}`
          : payment.paymentMethod,
        amount: centsToCurrency(payment.amount),
        patientId: payment.patientId,
        parentPaymentId: payment.parentPaymentId,
        id: payment.id,
      });
    }

    if (refundPaymentAction) {
      const refundPaymentBody = {
        paymentId: payment.id,
        codeRefundId: refundPaymentAction.selectedReason.id,
        refundMethod: refundPaymentAction.refundMethod,
        amount: refundPaymentAction.amount,
      };

      try {
        await refundPayment(refundPaymentBody);
        this.fetch();
        this.onPaymentAction();
        store.dispatch(openSuccess(REFUNDED_BANNER_MESSAGE.success));
      } catch (e) {
        store.dispatch(openError(REFUNDED_BANNER_MESSAGE.error));
      }
    }
  }

  async __allocatePayment() {
    await openOverlayAllocatePayment({
      patientId: this.patientId,
      payments: this.__getCheckedRows(),
    });

    this.fetch();
    this.onPaymentAction();
  }

  async __splitPayment() {
    const checkedRows = this.__getCheckedRows();

    if (checkedRows.length === 1) {
      const result = await openOverlay(OVERLAY_KEYS.SPLIT_PAYMENT, {
        payment: checkedRows[0],
      });

      if (result) {
        this.fetch();
        this.onPaymentAction();

        const refreshEvent = new CustomEvent(NEBULA_REFRESH_EVENT, {
          detail: {
            changed: [REFRESH_CHANGE_TYPE.LEDGER],
          },
        });
        window.dispatchEvent(refreshEvent);

        return true;
      }
    }

    return false;
  }

  async __associatePaymentProviderLocation() {
    const patientPayments = this.__getCheckedRows().filter(
      payment => !payment.payerPlan,
    );

    const providers = (await getActiveProviderUsers()).map(provider => ({
      item: provider,
      label: objToName(provider.name, DEFAULT_NAME_OPTS),
    }));

    const { locations } = store.getState().session.item;

    const allLocations = await getLocations({ hideInactive: true });

    const userLocations = allLocations.reduce((acc, location) => {
      if (locations.includes(location.id)) {
        acc.push({
          item: location,
          label: location.name,
        });
      }
      return acc;
    }, []);

    const result = await openPopup(
      POPUP_RENDER_KEYS.UPDATE_PAYMENT_PROVIDER_LOCATION,
      {
        providers,
        locations: userLocations,
        amountSelected: patientPayments.length,
      },
    );

    if (result) {
      const { provider, location } = result;
      const paymentIds = patientPayments.map(x => x.id);

      const body = {
        paymentIds,
        locationId: location ? location.id : null,
        providerId: provider ? provider.id : null,
      };

      try {
        await bulkUpdatePaymentAssociations({ body });
        this.fetch();
        store.dispatch(
          openSuccess(UPDATE_PAYMENT_PROVIDER_LOCATION_BANNER_MESSAGE.success),
        );
      } catch (e) {
        store.dispatch(
          openError(UPDATE_PAYMENT_PROVIDER_LOCATION_BANNER_MESSAGE.error),
        );
      }
    }
  }

  addIcon(iconName) {
    return this.__hasOwlItemizedReceiptBulkGeneration ? { icon: iconName } : {};
  }

  __getSelectedItems() {
    return {
      label: `${plural(this.__getCheckedRows().length, 'Item')} Selected`,
      showTextOnly: true,
    };
  }

  __getRefundPaymentActionDetail() {
    return {
      label: 'Refund Payment',
      onSelect: this.handlers.refundPayment,
      ...this.addIcon('neb:refund'),
    };
  }

  __getVoidPaymentActionDetail() {
    return {
      label: 'Void Payment',
      onSelect: this.handlers.voidPayment,
      ...this.addIcon('neb:clear'),
    };
  }

  __getAllocatePaymentActionDetail() {
    return {
      label: 'Allocate Payment',
      onSelect: this.handlers.allocatePayment,
      ...this.addIcon('neb:allocate'),
    };
  }

  __getSplitPaymentActionDetail() {
    return {
      label: 'Split Payment',
      onSelect: this.handlers.splitPayment,
      ...this.addIcon('neb:split'),
    };
  }

  __getPrintPaymentActionDetail() {
    return {
      label: 'Print Receipt',
      onSelect: this.handlers.printPayment,
      ...this.addIcon('neb:receipt'),
    };
  }

  __getPrintItemizedReceiptsActionDetail() {
    return {
      label: 'Print Itemized Receipts',
      onSelect: this.handlers.printItemizedReceipts,
      ...this.addIcon('neb:receipt'),
    };
  }

  __getUpdateProviderLocationDetail() {
    return {
      label: 'Update Provider/Location',
      onSelect: this.handlers.onUpdateProviderLocation,
      ...this.addIcon('neb:edit'),
    };
  }

  __getSelectAllOnEveryPageActionDetail() {
    const totalCount = this.__allPayments?.count || 0;

    return {
      label: `Select ${totalCount} Items`,
      onSelect: this.handlers.onSelectAllItems,
    };
  }

  __getSelectAllOnCurrentPageActionDetail() {
    return {
      label: `Select ${plural(
        this.service.__items.length,
        'Item',
      )} on This Page`,
      onSelect: this.handlers.onSelectAllOnCurrentPage,
    };
  }

  __getDeselectAllActionDetail() {
    const selectedCount = this.__getCheckedRows().length;
    const label = this.__hasOwlItemizedReceiptBulkGeneration
      ? `Deselect ${plural(selectedCount, 'Item')}`
      : `Deselect All - ${plural(selectedCount, 'Item')} Selected`;

    return {
      label,
      onSelect: this.handlers.onDeselectAll,
    };
  }

  __areAllCurrentPageItemsSelected() {
    if (!this.__hasOwlItemizedReceiptBulkGeneration) {
      return false;
    }

    return this.__tableState.pageItems.every(item =>
      this.__selectionState.selectedIds.has(item.id),
    );
  }

  __getAutoAllocateValidActionDetail() {
    const validPaymentIds = this.__getValidCheckedPaymentIds();

    return {
      label: `Auto Allocate - ${plural(
        validPaymentIds.length,
        'Valid Item',
      )} Selected`,
      onSelect: this.handlers.autoAllocateAll,
      ...this.addIcon('neb:flashAuto'),
    };
  }

  __getValidCheckedPaymentIds() {
    if (!this.__hasOwlItemizedReceiptBulkGeneration) {
      return this.__tableState.pageItems
        .filter(
          item =>
            item.checked &&
            item.status === 'Unallocated' &&
            !item.payerPlan &&
            (!this.__hasLsTaxDiscountFF || !item.codeDiscountId),
        )
        .map(item => item.id);
    }

    const selectedIds = Array.from(this.__selectionState.selectedIds);
    return selectedIds.filter(id => {
      const payment = this.__allPayments.data.find(item => item.id === id);

      return (
        payment &&
        payment.status === 'Unallocated' &&
        !payment.payerPlan &&
        (!this.__hasLsTaxDiscountFF || !payment.codeDiscountId)
      );
    });
  }

  __handleRowCheck(e) {
    const terms = e.name.split('.');
    const index = terms[terms.length - 1];
    const itemId = this.__tableState.pageItems[index].id;

    if (e.value) {
      this.__selectionState.selectedIds.add(itemId);
    } else {
      this.__selectionState.selectedIds.delete(itemId);
    }

    const updatedItem = {
      ...this.__tableState.pageItems[index],
      checked: e.value,
    };

    this.__tableState.pageItems.splice(index, 1, updatedItem);
    this.__tableState = {
      ...this.__tableState,
      pageItems: [...this.__tableState.pageItems],
      ...(this.__hasOwlItemizedReceiptBulkGeneration && {
        selectAllChecked: this.__areAllCurrentPageItemsSelected(),
      }),
    };

    this.changeOccurred(this.__tableState);
  }

  __getHeaderActions() {
    const checkedRows = this.__getCheckedRows();
    const hasSelectedItems = this.__selectionState.selectedIds.size > 0;
    const hasCheckedRows = checkedRows.length > 0;

    let electronic;
    let hasSplit;
    let unallocatable;
    let disableVoid;
    let hasDiscount;
    let hasERA;
    let hasPatientPayments;
    let allArePatientPayments;
    let noneHaveERA;
    let noneHaveSplit;
    let noneHaveDiscount;
    let noneHaveRefunds;

    if (hasCheckedRows) {
      electronic = isElectronicPayment(checkedRows[0]);
      hasSplit = checkedRows[0].parentPaymentId;
      unallocatable = ['Unallocated', 'Allocated'].includes(
        checkedRows[0].status,
      );

      disableVoid = electronic || hasSplit || !unallocatable;
      hasDiscount = checkedRows[0].codeDiscountId;
      hasERA = checkedRows[0].eRA;
      hasPatientPayments = checkedRows.some(p => !p.payerPlanId);
      allArePatientPayments = checkedRows.every(p => !p.payerPlanId);
      noneHaveERA = checkedRows.every(p => !p.eRA);
      noneHaveSplit = checkedRows.every(p => !p.parentPaymentId);
      noneHaveDiscount = checkedRows.every(p => !p.codeDiscountId);
      noneHaveRefunds = checkedRows.every(
        p => !p.refundedAt && p.status !== 'Refunded',
      );
    }

    if (this.__hasOwlItemizedReceiptBulkGeneration) {
      const actions = [];
      const allItemsSelected =
        this.__selectionState.selectedIds.size === this.__allPayments.count;

      actions.push(this.__getSelectedItems());

      if (!allItemsSelected) {
        actions.push(this.__getSelectAllOnEveryPageActionDetail());
      }

      if (hasSelectedItems) {
        actions.push(this.__getDeselectAllActionDetail());

        if (hasCheckedRows) {
          if (checkedRows.length === 1) {
            if (checkedRows[0].available === checkedRows[0].amount) {
              if (!hasDiscount && !hasERA) {
                actions.push(this.__getRefundPaymentActionDetail());
              }
            }

            if (!hasDiscount && !disableVoid) {
              actions.push(this.__getVoidPaymentActionDetail());
            }
          }

          if (checkedRows.every(p => p.available > 0)) {
            actions.push(this.__getAllocatePaymentActionDetail());
          }

          if (
            checkedRows.length >= 1 &&
            !checkedRows[0].payerPlanId &&
            !checkedRows[0].parentPaymentId &&
            this.__shouldShowPrintReceipt(checkedRows.length)
          ) {
            actions.push(this.__getPrintPaymentActionDetail());
          }

          if (
            allArePatientPayments &&
            noneHaveERA &&
            noneHaveSplit &&
            noneHaveDiscount &&
            noneHaveRefunds
          ) {
            actions.push(this.__getPrintItemizedReceiptsActionDetail());
          }

          if (hasPatientPayments) {
            actions.push(this.__getUpdateProviderLocationDetail());
          }

          if (
            this.__selectionState.selectedIds.size <= 10 &&
            this.__getValidCheckedPaymentIds().length > 0
          ) {
            actions.push(this.__getAutoAllocateValidActionDetail());
          }

          if (checkedRows.length === 1) {
            const payment = checkedRows[0];

            const hasPaymentDiscount = !!payment.codeDiscountId;

            const canSplit =
              !hasPaymentDiscount && payment.status === 'Unallocated';

            if (
              canSplit &&
              !actions.some(action => action.label === 'Split Payment')
            ) {
              actions.push(this.__getSplitPaymentActionDetail());
            }
          }
        }
      }

      return actions;
    }

    if (!hasCheckedRows) {
      const basicActions = [this.__getSelectAllOnCurrentPageActionDetail()];

      if (hasSelectedItems) {
        basicActions.push(this.__getDeselectAllActionDetail());
      }

      return basicActions;
    }

    const actions = [];

    actions.push(this.__getSelectAllOnCurrentPageActionDetail());

    if (hasSelectedItems) {
      actions.push(this.__getDeselectAllActionDetail());
    }

    if (checkedRows.length === 1) {
      if (checkedRows[0].available === checkedRows[0].amount) {
        if (!hasDiscount && !hasERA) {
          actions.push(this.__getRefundPaymentActionDetail());
        }
      }

      if (!hasDiscount && !disableVoid) {
        actions.push(this.__getVoidPaymentActionDetail());
      }
    }

    if (this.__shouldShowAutoAllocate()) {
      actions.push(this.__getAutoAllocateValidActionDetail());
    }

    if (checkedRows.every(p => p.available > 0)) {
      actions.push(this.__getAllocatePaymentActionDetail());
    }

    const allHaveNoDiscount = checkedRows.every(p => !p.codeDiscountId);
    const allNonSplit = checkedRows.every(p => !p.parentPaymentId);

    if (
      allHaveNoDiscount &&
      checkedRows.length === 1 &&
      ((!hasSplit && checkedRows[0].status === 'Unallocated') ||
        (hasSplit && checkedRows[0].status === 'Refunded'))
    ) {
      actions.push(this.__getSplitPaymentActionDetail());
    }

    if (
      allHaveNoDiscount &&
      checkedRows.every(p => !p.payerPlanId) &&
      allNonSplit &&
      this.__shouldShowPrintReceipt(checkedRows.length)
    ) {
      actions.push(this.__getPrintPaymentActionDetail());
    }

    if (checkedRows.length && hasPatientPayments) {
      actions.push(this.__getUpdateProviderLocationDetail());
    }

    return actions;
  }

  __shouldShowAutoAllocate() {
    return (
      this.__selectionState.selectedIds.size <= 10 &&
      this.__getValidCheckedPaymentIds().length > 0
    );
  }

  __shouldShowPrintReceipt(count) {
    return !this.__hasOwlItemizedReceiptBulkGeneration || count <= 10;
  }

  __printItemizedReceipts() {
    const checkedRows = this.__getCheckedRows();
    return this.__generateItemizedReceipts(checkedRows);
  }

  async __generateItemizedReceipts(payments) {
    const allLocations = await getLocations({ hideInactive: false });

    const locationsMap = {};
    allLocations.forEach(location => {
      locationsMap[location.id] = location;
    });

    const paymentsByLocation = {};

    try {
      await Promise.all(
        payments.map(async payment => {
          try {
            const detailedPayment = await getPaymentDetail(payment.id, true);

            let patientData = null;

            if (detailedPayment.patientId) {
              patientData = await patientApiClient.fetchOne(
                detailedPayment.patientId,
                true,
                true,
              );
            }

            const patientName = patientData
              ? `${patientData.lastName || ''}, ${patientData.firstName || ''} ${patientData.middleName || ''} `.trim()
              : detailedPayment.patientName;

            let patientAddress = {
              address1: '',
              address2: '',
              city: '',
              state: '',
              zipCode: '',
            };

            if (patientData?.addresses?.length) {
              const primaryAddress =
                patientData.addresses.find(addr => addr.isPrimary) ||
                patientData.addresses[0];

              if (primaryAddress) {
                patientAddress = {
                  address1: primaryAddress.address1 || '',
                  address2: primaryAddress.address2 || '',
                  city: primaryAddress.city || '',
                  state: primaryAddress.state || '',
                  zipCode: primaryAddress.zipcode || '',
                };
              }
            }

            const { locationId } = payment;

            let location = locationsMap[locationId];

            if (!location && allLocations.length > 0) {
              location = allLocations[0];
            }

            if (location) {
              const formattedLocation = {
                name: location.name,
                practiceName: location.name,
                address: {
                  address1: location.address1 || '',
                  address2: location.address2 || '',
                  city: location.city || '',
                  state: location.state || '',
                  zipCode: location.zipCode || '',
                },
                phoneNumber: formatPhoneNumber(location.phoneNumber) || '',
                email: location.emailAddress || '',
                organizationNPI: location.organizationNPI || '',
                taxId: location.taxId || '',
                timezone: location.timezone || 'America/New_York',
              };

              const paymentWithLocationInfo = {
                ...detailedPayment,
                locationId: location.id,
                locationInfo: formattedLocation,
                patientName,
                patientAddress,
              };

              if (!paymentsByLocation[location.id]) {
                paymentsByLocation[location.id] = [];
              }
              paymentsByLocation[location.id].push(paymentWithLocationInfo);
            }
          } catch (error) {
            console.error(`Error processing payment ${payment.id}:`, error);
            throw error;
          }
        }),
      );

      const sortedLocationIds = Object.keys(paymentsByLocation).sort((a, b) => {
        const locationA = locationsMap[a].name.toLowerCase();
        const locationB = locationsMap[b].name.toLowerCase();
        return locationA.localeCompare(locationB);
      });

      const allPayments = [];

      sortedLocationIds.forEach((locationId, index) => {
        const locationPayments = paymentsByLocation[locationId];

        if (locationPayments.length > 0) {
          locationPayments.sort(
            (a, b) => new Date(a.transactionDate) - new Date(b.transactionDate),
          );

          if (index > 0) {
            locationPayments[0].pageBreakBefore = true;
            locationPayments[0].forceNewPage = true;
          }

          allPayments.push(...locationPayments);
        }
      });

      if (allPayments.length > 0) {
        const buffer = await printItemizedReceipt({
          patientPayments: allPayments,
          isCombined: true,
          forcePageBreaks: true,
          startNewPage: true,
        });

        const printPromise = new Promise(resolve => {
          resolve(buffer);
        });

        await printPdf(printPromise);
      }
    } catch (error) {
      console.error('Error generating itemized receipts:', error);
      store.dispatch(openError('Failed to generate itemized receipts'));
      throw error;
    }
  }

  __renderCheckBox(item, index, changeHandler) {
    return html`
      <neb-checkbox
        id="${ELEMENTS.checkBox.id}"
        .name="${index}"
        .onChange="${changeHandler}"
        ?checked="${item.checked}"
        @click="${this.handlers.checkBoxToggle}"
      ></neb-checkbox>
    `;
  }

  async __selectCell(key, index) {
    if (key !== 'checked') {
      const item = this.__tableState.pageItems[index];

      if (item.codeDiscountId) {
        await openOverlay(OVERLAY_KEYS.DISCOUNT, {
          id: item.id,
          patientId: item.patientId,
        });
      } else {
        const { checked, ...paymentWithoutChecked } = item;

        await openPayment({
          payment: paymentWithoutChecked,
          readonly: false,
        });
      }

      this.fetch();
      this.onPaymentAction();
    }
  }

  async __getPatientPaymentPayers() {
    const patientPaymentPayers = await getPatientPaymentPayers(this.patientId);
    const uniquePaymentPayers = new Map();

    patientPaymentPayers.forEach(payer => {
      uniquePaymentPayers.set(payer.alias, { label: payer.alias, data: payer });
    });

    this.__patientPaymentPayers = Array.from(uniquePaymentPayers.values());
  }

  __getEmptyMessage() {
    if (this.__isInitialLoadDisabled()) {
      return NO_ITEMS_INITIAL_LOAD;
    }

    return this.patientId
      ? 'There are no payments for this patient.'
      : 'There are no payments.';
  }

  firstUpdated() {
    if (this.getConfig().showInactiveFilter !== undefined) {
      this.service.hideInactive(this.getConfig().showInactiveFilter);
    }
  }

  async updated(changed) {
    if (changed.has('__tableState')) {
      this.__calculateMarginBottom();

      if (this.patientId) {
        this.__getPatientPaymentPayers();

        if (
          this.__hasOwlItemizedReceiptBulkGeneration &&
          !this.__allPayments.data.length
        ) {
          await this.__fetchAllPayments();
        }
      }
    }

    if (changed.has('patientId')) {
      this.__clearAllSelections();
      this.__allPayments = { data: [] };

      this.__tableState = {
        ...this.__tableState,
        pageItems: this.__tableState.pageItems.map(item => ({
          ...item,
          checked: false,
        })),
        selectAllChecked: false,
      };

      this.getDynamicLeadingHeaderActions = () => this.__getHeaderActions();

      this.requestUpdate();
      this.fetch();
    }
  }

  static get styles() {
    return [
      super.styles,
      css`
        :host([__hasOwlItemizedReceiptBulkGeneration]) .filters {
          margin-bottom: 0;
        }

        .cell-spacer {
          --custom-icon-spacer-max-height: ${CSS_SPACING};
        }

        .filters {
          margin-bottom: ${CSS_SPACING};
          display: flex;
        }

        .row {
          flex-direction: column;
        }

        .container {
          overflow: visible;
        }

        .footer {
          display: flex;
          padding: 18px ${CSS_SPACING};
          background-color: ${CSS_COLOR_GREY_3};
        }

        .footer-cell {
          display: flex;
          width: 0;
          margin-right: ${CSS_SPACING};
          min-width: 0;
          white-space: nowrap;
          word-break: break-all;
        }

        .footer-cell:last-child {
          margin-right: 0;
        }

        .text-bold {
          font-weight: ${CSS_FONT_WEIGHT_BOLD};
        }

        .footer-cell-spacer {
          max-width: 28px;
          margin-right: ${CSS_SPACING};
        }

        .footer-trailing-spacer {
          width: 12px;
        }
      `,
    ];
  }

  renderPagination() {
    return this.__tableState.pageCount > 1
      ? html`
          <div class="row row-margins">
            <div class="cell cell-spacer"></div>
            <div class="cell">
              <neb-pagination
                id="${ELEMENTS.pagination.id}"
                .debouncerDelay="${300}"
                .pageCount="${this.__tableState.pageCount}"
                .currentPage="${this.__tableState.pageIndex}"
                .onPageChanged="${this.handlers.selectPage}"
              ></neb-pagination>
            </div>
          </div>
        `
      : '';
  }

  renderTableFooter() {
    if (
      Object.keys(this.__tableState.body).length &&
      Object.keys(this.__tableState.body.totals).length
    ) {
      const cells = this.__totalsConfig()
        .slice(1)
        .map(cell => {
          const value = cell.formatter
            ? cell.formatter(this.__tableState.body.totals[cell.key])
            : '';

          return html`
            <span class="footer-cell text-bold" style="flex: ${cell.flex}"
              >${value}</span
            >
          `;
        });

      return html`
        <div id="${ELEMENTS.footer.id}" class="footer">
          <div class="footer-cell-spacer text-bold">Totals</div>
          ${cells}
          <div class="footer-trailing-spacer"></div>
        </div>
      `;
    }

    return '';
  }

  renderTable() {
    const emptyMessage = this.__getEmptyMessage();

    return html`
      <neb-filters-ledger-payments
        id="${ELEMENTS.filters.id}"
        class="filters"
        .onApply="${this.handlers.filter}"
        ?enablePatient="${!this.patientId}"
        .patientPayers="${this.__patientPaymentPayers}"
      ></neb-filters-ledger-payments>

      <div
        id="${ELEMENTS.content.id}"
        style="margin-bottom: ${unsafeCSS(this.__marginBottom)}px;"
      >
        <neb-table-ledger-payments
          id="${ELEMENTS.table.id}"
          class="cell-spacer"
          .layout="${this.layout}"
          .config="${this.__buildConfig()}"
          .subHeaderConfig="${this.__totalsConfig()}"
          .model="${this.__tableState.pageItems}"
          .body="${this.__tableState.body}"
          .patientId="${this.patientId}"
          .sortParams="${[this.__tableState.sortParams]}"
          .emptyMessage="${emptyMessage}"
          .onChange="${this.handlers.tableChange}"
          .onSort="${this.handlers.sort}"
          .onClickNoteIcon="${this.handlers.openBillingNotesOverlay}"
          .onSelectCell="${this.handlers.selectItemCell}"
          .onSelectPatient="${this.handlers.navigateToPatientLedger}"
          .onSelectPayer="${this.handlers.editPayer}"
          .onToggleCheckbox=${this.handlers.toggleCheckbox}
          .onBulkActionClick="${this.getDynamicLeadingHeaderActions}"
          .hasOwlItemizedReceiptBulkGeneration=${this
            .__hasOwlItemizedReceiptBulkGeneration}
          .selectedItemIds=${this.__selectionState.selectedIds}
          .showBulkActionMenu=${this.__hasOwlItemizedReceiptBulkGeneration &&
          !!this.patientId}
          .bulkActionItems=${this.__getHeaderActions()}
        ></neb-table-ledger-payments>
        ${this.renderTableFooter()}
      </div>
    `;
  }
}

customElements.define('neb-payments-list-page', NebPaymentsListPage);
